import {cva} from 'class-variance-authority'

export const buttonClasses = cva(
    'inline-flex whitespace-nowrap items-center transition-colors ease-in duration-200 cursor-pointer',
    {
        variants: {
            variant: {
                primary: '',
                secondary:
                    'font-medium text-slate-600/85 bg-slate-300/10 dark:bg-slate-100 border border-slate-950/10 dark:border-slate-200/25 backdrop-filter-[12px] hover:bg-slate-300/25 hover:dark:bg-slate-100/15',
                navigation:
                    'font-medium text-text-secondary hover:text-text-primary hover:bg-bg-quinary',
                vibrant:
                    'text-white dark:text-white font-medium bg-gradient-to-r from-orange-400 via-rose-500 to-pink-500 hover:from-orange-400 hover:to-orange-500',
                contact:
                    'text-primary dark:text-dark-primary font-medium bg-slate-300/15 border border-gray-600/80 hover:bg-slate-200/75',
                high: 'bg-gradient-to-b from-sky-500 to-blue-600 text-white border-b border-b-blue-900 hover:from-blue-600 hover:to-blue-600',
                section: 'text-slate-800/85 dark:text-slate-300 font-medium',
                changelog:
                    'w-full flex flex-col hover:backdrop-blur-3xl hover:bg-slate-200/55 hover:dark:bg-slate-300/25 flex-1 rounded-lg shadow p-4 text-sm leading-6 border text-slate-800 dark:text-slate-300 disabled:hidden',
                plus: 'border border-slate-600/85 text-white bg-slate-500/85 transition ease-in-out hover:bg-slate-950/75 px-3 py-2 rounded-full font-medium items-center',
                chevronCarousel:
                    'disabled:text-gray-200 disabled:border-gray-200 size-10 border rounded-full flex items-center justify-center disabled:dark:text-slate-300/25 disabled:dark:bg-zinc-600/25',
                submit: 'col-span-2 bg-blue-600 text-white py-2 px-4 rounded',
                hamburger:
                    'inline-flex items-center justify-center rounded-sm p-3 transition-colors duration-300 group inline-flex w-12 h-12 text-slate-800 text-center items-center justify-center rounded transition dark:text-slate-300',
                patientList:
                    'flex text-white focus:outline-none size-4 justify-center items-center',
                calender:
                    'border bg-slate-200/25 py-1 px-3 hover:bg-slate-200/55 hover:text-slate-900',
                calenderChevron:
                    'w-10 h-10 p-1 text-slate-500/85 hover:bg-slate-200/55 hover:text-slate-900',
            },
            size: {
                icon: 'p-2',
                small: 'text-sm h-8 px-2',
                medium: 'text-base h-9 px-3',
                large: 'text-lg h-11 px-4',
                xlarge: 'text-xl h-12 px-4',
            },
            shape: {
                round: 'rounded-full',
                roundXL: 'rounded-2xl',
                rounded: 'rounded-md',
            },
            width: {
                full: 'w-full justify-center',
            },
        },
    },
)
