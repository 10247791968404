'use client'

import {motion} from 'framer-motion'
import {useTranslations} from 'next-intl'

import InternalButton from '../ui/internalbutton'
import {navLinks} from '../utils/getNavLinks'
import H1Title from './H1Title'
import Hero from './Hero'
import HeroSubtitle from './HeroSubtitle'

const fadeInUp = {
    hidden: {opacity: 0, y: 80, filter: 'blur(10px)'},
    visible: {opacity: 1, y: 0, filter: 'blur(0px)'},
}

export default function HeroMain() {
    const t = useTranslations()
    return (
        <Hero variant="primary" className="mt-32 items-center">
            <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{duration: 0.8}}
                className="blur-content "
            >
                <H1Title variant="primary">{t('Main.hero')}</H1Title>
            </motion.div>
            <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{duration: 0.8}}
                className="blur-content"
            >
                <HeroSubtitle variant="primary">{t('Main.herosub')}</HeroSubtitle>
            </motion.div>
            <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{duration: 0.8}}
                className="blur-content"
            >
                <InternalButton
                    href={navLinks.contact}
                    variant="vibrant"
                    size="xlarge"
                    shape="round"
                >
                    {t('Main.largeCTA')}
                </InternalButton>
            </motion.div>
        </Hero>
    )
}
